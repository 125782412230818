import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query'
import OrganizationStatus from './components/OrganizationStatus';
import logo from './contentfulspacecheck.png';
import './App.css';

function App() {
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <header className="App-header">
          <a href='https://contentfultesting.statuspage.io/'>
            <img src={logo} alt="logo" />
          </a>
          <OrganizationStatus />
        </header>
      </div>
    </QueryClientProvider>
  );
}

export default App;
