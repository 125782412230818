import React from 'react';
import axios from 'axios';
import moment from 'moment'
import { useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query'
import { Input, Table, Space, Divider, Typography, Spin } from 'antd';
import { CheckCircleTwoTone, ExclamationCircleTwoTone } from '@ant-design/icons';

const { Search } = Input;
const { Title } = Typography
const timeFormat = "HH:mm"

enum Status {
  Up = 'up',
  Down = 'down',
}

const fetchOrganizationStatus = async (spaceId: string | null) =>
  axios.get(`https://poc-status-page-api.contentful.tools/checks/${spaceId}`)


const fetchCheckStatus = async (checkId: string, from: number, to: number) => axios
  .get(`https://poc-status-page-api.contentful.tools/summary.outage/${checkId}?from=${from}&to=${to}`)

const HistoryColumn = function ({ checkId }: { checkId: string }) {
  const thirtyDaysAgo = moment().startOf('day').add(9, 'hours').add(56, 'minutes').unix()
  const now = moment().unix()

  const { isLoading, data } = useQuery(`summary.outage-${checkId}`, () => fetchCheckStatus(checkId, thirtyDaysAgo, now))

  return (
    <Spin key={checkId} spinning={isLoading} tip="Loading...">
      <ul>
        {data?.data.summary.states.map((x: any) => {
          const timeFrom = x.timefrom * 1000
          const timeTo = x.timeto * 1000
          const timeFromFormatted = moment(timeFrom).format(timeFormat)
          const timeToFormatted = moment(timeTo).format(timeFormat)
          return (
            <p>{x.status === Status.Up ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <ExclamationCircleTwoTone twoToneColor="red" />} {timeFromFormatted} - {timeToFormatted}</p>
          )
        })}
      </ul>
    </Spin>
  )
}

const columns = (spaceId: string) => ([
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text: string) => {
      // const lastIndex = text.lastIndexOf(' ');

      // return <>{text.substring(0, lastIndex)}</>
      return <>{text}</>
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: string) => (
      <Space>
        {status === Status.Up && spaceId !== 'testingOutage' ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <ExclamationCircleTwoTone twoToneColor="red" />}
      </Space>
    )
  },
  {
    title: 'History',
    dataIndex: 'id',
    key: 'id',
    render: (checkId: string) => {
      return <HistoryColumn key={checkId} checkId={checkId} />
    },
  }
]);

function GetSummary(props: any) {
  const { spaceId } = props
  const checks: any[] = props.checks
  const isAnyDown = spaceId !== 'testingOutage' ? checks.some((check) => check.status === Status.Down) : true
  if (isAnyDown) return <img width="400" src="https://images.ctfassets.net/0d30cto7ao8v/19kEwkJX6HS9COlGpUwzAJ/9bcd175e09c9353c0612e725c7c03451/thisisfine.png"></img>

  const lastDownEndChecks = checks.map((check) => check.lastdownend as number)
  const lastDown = Math.min(...lastDownEndChecks)

  return <Title level={2}>Contentful is up and running. Last major outage was {moment.unix(lastDown).fromNow()}</Title>
}

const mapping: any = {
  'Delivery List': 'Content Delivery API',
  'Delivery List USW2': null,
  'Delivery Filtered': null,
  'Mgmt List': 'Content Management API - Read',
  'Mgmt Asset Processing': 'Content Management API - Asset Processing',
  'Mgmt Create': 'Content Management API - Write',
  'Preview List': 'Content Preview API',
  'GraphQL query': 'GraphQL',
}

function OrganizationStatus() {
  const [searchParams, setSearchParams] = useSearchParams()
  const spaceId = searchParams.get('spaceId')
  const { isLoading, data, error } = useQuery('checks', () => fetchOrganizationStatus(spaceId), { enabled: !!spaceId })

  const shouldRenderResults = spaceId && !isLoading && !error && data?.data?.checks?.length > 0 || (spaceId === 'testingOutage')

  const dataSource = data?.data.checks.map((check: any) => {
    const lastIndex = check.name.lastIndexOf(' ');
    const name = check.name.substring(0, lastIndex)
    return ({
      ...check,
      name: mapping[name],
    })
  }).filter((check: any) => check.name !== null)

  return (
    <div>
      <Title>{shouldRenderResults ? `Space ID: ${spaceId}` : 'Sorry, invalid Space ID'}</Title>
      {shouldRenderResults && <GetSummary spaceId={spaceId} checks={data?.data.checks} />}

      {!shouldRenderResults && <Search placeholder="Space ID" loading={isLoading} allowClear onSearch={(spaceId => setSearchParams({ spaceId }))} style={{ width: 200 }} />}
      {spaceId && (
        <>
          <Divider dashed />
          <Table pagination={false} loading={isLoading} columns={columns(spaceId)} dataSource={dataSource ?? []} />
        </>
      )}
    </div>
  )
}

export default OrganizationStatus;